<template>
  <v-container>
    <v-card>
      <v-data-table v-bind="bind.table" :search="search">
        <template #top>
          <div class="d-flex pa-3">
            <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
            <v-divider v-bind="bind.divider"></v-divider>
            <v-spacer></v-spacer>
            <v-btn v-bind="bind.refresh" v-on="on.refresh">
              <v-icon>{{ icons.refresh }}</v-icon>
            </v-btn>
            <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
          </div>
        </template>

        <template #item.actions="{ item }">
          <v-btn v-bind="bind.actions" v-on="on.edit(item)"
            ><v-icon>{{ icons.edit }}</v-icon></v-btn
          >
          <v-btn color="error" v-bind="bind.actions" v-on="on.delete(item)"
            ><v-icon>{{ icons.delete }}</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <Modal v-model="dialog" v-on="on.form" v-bind="bind.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/Modal/ModalProduct/Modal-Financiera";

import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "FinancieraCrudPage",
  mixins: [PageMixin],
  components: {
    Modal,
  },
  data: () => ({
    headers: [
      { text: "Financiera", value: "financiera_descripcion", align: "left" },
      { text: "Código", value: "codigo", align: "center" },
      {
        text: "Tipo Documento",
        value: "tipo_documento",
        align: "left",
      },
      { text: "Numero Documento", value: "numero_documento", align: "left" },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    financiera: [],
  }),

  computed: {
    ...mapGetters("Financiera", ["getItems"]),

    items() {
      return this.getItems;
    },
  },

  methods: {
    ...mapActions("Financiera", ["GET", "POST", "PUT", "DELETE"]),

    setup() {
      this.loading = true;
      this.GET()
        .catch((error) => {
          this.snackbar(error.result?.message || error.info);
        })
        .finally(() => (this.loading = false));
    },

    saveItem(data) {
      this.loadingForm = true;
      let method = this.POST;
      const formData = { ...data };
      if (this.selected) {
        method = this.PUT;
        formData.id = this.selected.financiera;
      }
      method(formData)
        .then(async (response) => {
          this.snackbar(response.info, "success");
          this.closeForm();
          this.setup();
        })
        .catch((error) => {
          this.snackbar(error.result?.message || error.info);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },

    deleteItem(item) {
      this.confirm({
        title: "Eliminar financiera",
        description:
          "Esta a punto de eliminar esta financiera ¿Desea proceder?",
      }).then((answer) => {
        if (answer) {
          this.DELETE(item.financiera)
            .then((response) => {
              this.snackbar(response.info, "success");
              this.setup();
            })
            .catch((error) => {
              this.snackbar(error.result?.message || error.info);
            });
        }
      });
    },
  },
};
</script>
