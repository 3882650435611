<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog">
    <v-card>
      <v-card-title primary-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close"
          ><v-icon>{{ icons.close }}</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <v-row multi-line>
            <!-- CAMPO NOMBRE -->
            <v-col cols="12" md="8">
              <v-text-field
                v-model="form.financiera_descripcion"
                name="financiera"
                label="Financiera"
                placeholder="Escriba un nuevo nombre de financiera..."
                :rules="[(v) => !!v || 'El campo nombre es requerido.']"
              ></v-text-field>
            </v-col>

            <!-- CAMPO CODIGO -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.codigo"
                label="Código"
                placeholder="Escriba el código de la financiera..."
                :rules="[(v) => !!v || 'El campo código es requerido.']"
              ></v-text-field>
            </v-col>

            <!-- CAMPO NRO DOCUMENTO -->
            <v-col cols="12" md="8">
              <v-text-field
                v-model="form.numero_documento"
                :rules="[
                  (v) => !!v || 'El campo numero de documento es requerido.',
                ]"
                name="numeroDoc"
                label="Número de documento"
                placeholder="Escriba el numero de documento..."
              >
              </v-text-field>
            </v-col>

            <!-- CAMPO TIPO DOCUMENTO -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.tipo_documento"
                name="tipoDoc"
                label="Tipo de documento"
                placeholder="Escriba el tipo de documento..."
                :rules="[
                  (v) => !!v || 'El campo tipo de documento es requerido.',
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit">
          {{ labels.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";
export default {
  name: "FinancieraModalForm",
  mixins: [FormMixin],
  data() {
    return {
      width: 600,
      defaultTitle: "Formulario de Financiera",
      form: {
        financiera_descripcion: "",
        tipo_documento: "",
        numero_documento: "",
        codigo: "",
      },
    };
  },
  methods: {},
};
</script>
